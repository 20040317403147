import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UseLanding } from './landing.model';
import { useTicket } from '../../hooks';
import uuid from 'react-uuid';


export const useLanding = (): UseLanding => {
  const params = useParams();
  const location = useLocation()
  const [showMessage, setShowMessage] = useState(false);
  const { id } = params;
  const { setTicketId } = useTicket();

  const handleNoButtonClick = () => {
    setShowMessage(true);
  };

  useEffect(() => {
    if (location.pathname === '/entry') {
      setTicketId(uuid());
    } else if (id) {
      setTicketId(id);
    }
  }, [id, location.pathname, setTicketId]);

  return {
    showMessage,
    handleNoButtonClick,
  };
};
